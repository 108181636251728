import { AppBarStyle, TopBarShortCutLink } from "../utils/styles"
import axios from "axios";
import { User, AuthStatus } from "../utils/types";
import { createContext, useContext, useEffect, useState } from "react";
import { getAvatarURL } from "../utils/getURL";
import { GuildContext } from "../utils/contexts/GuildContext";
import { useFetchUser } from "../utils/hooks/useFetchUser";
import { FaBars, FaHome } from "react-icons/fa";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { BsSlashSquareFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { getAuthStatus, getAuthStatusLocalhost } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { hideLoading } from "../App";

// type UserContextType = {
//     user?: AuthStatus;
//     updateUser: (user: AuthStatus) => void;
// }
// type ProfileContextType = {
//     profile?: User;
//     updateProfile: (profile: User) => void;
// }
// export const AuthStatusContext = createContext<UserContextType>({
//     updateUser: () => {},
// });
// export const ProfileContext = createContext<ProfileContextType>({
//     updateProfile: () => {},
// });

export const AppBar = () => {
    const { guild } = useContext(GuildContext);
    const { user, errorUser, loadingUser } = useFetchUser();
    // const [user, setUser] = useState<AuthStatus>();
    // const [errorUser, setError] = useState('');
    const [profile, setProfile] = useState<User>();
    const [menuActive, setMenuActive] = useState(false);
    const navigate = useNavigate();

    // console.log(guild);
    
    useEffect(() => {
        if(user){
            axios.get<User>('https://discord.com/api/users/@me', {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                }
            }).then(({data}) => {
                setProfile(data);
            }).catch((err) => {
                console.error(`UserData: ${err}`);
            });
        };
        
        hideLoading();
    
        window.addEventListener('load', hideLoading);
    
        return () => {
            window.removeEventListener('load', hideLoading);
        };
    });

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };
    
    // if (profile) {
    //     return (
    //     <div>
    //         {/* <AppBarStyle>
    //             <p>{profile.global_name}</p>
    //             {profile.avatar && <img src={getAvatarURL(profile)} alt={profile.username} height={45} width={45} style={{ borderRadius: '50%' }} />}
    //             {!profile.avatar && <img src='https://cdn.discordapp.com/embed/avatars/0.png' alt={profile.username} height={45} width={45} style={{ borderRadius: '50%' }} />}
    //         </AppBarStyle> */}

    //         <div style={{ position: "fixed", display: "flex", alignItems: "center", justifyContent: "space-between", background: "#171717e0", boxShadow: "0 2px 10px 0 rgba(23, 23, 23, 0.88)", top: 0, zIndex: 100, width: "100%", height: 60, maxHeight: "40%", }} >
    //         <div style={{ display: "flex", justifyContent: "space-between", marginLeft: 20, marginRight: 20, width: "100%", }} >
    //             <a href="/" style={{ color: "white", textDecoration: "none", width: 150, fontSize: 20, display: "flex", alignItems: "center", }}>
    //                 <img src="/assets/images/logo2.png" alt="logo" width={40} style={{ borderRadius: 5, marginRight: 15, }} />
    //                 <span>Camming</span>
    //             </a>
    //             <div className={`topBarShortCutLink ${menuActive ? 'mobile-active' : ''}`} style={{ width: "40%", justifyContent: "space-between", alignItems: "center", animation: menuActive ? 'mobile-active-show 0.5s ease-out;' : 'mobile-active-hide 0.5s ease-out;', }}>
    //                 <TopBarShortCutLink href='/homepagetest' isActive={window.location.pathname == '/homepagetest'}>
    //                     <FaHome style={{ marginRight: 10, }} />
    //                     <span>Home</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item2</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item3</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item4</span>
    //                 </TopBarShortCutLink>
    //                 <TopBarShortCutLink isActive={window.location.pathname == '/null'}>
    //                     <span>item5</span>
    //                 </TopBarShortCutLink>
    //             </div>
    //             <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: 150, }}>
    //                 <a style={{ display: "flex", alignItems: "center",  cursor: "pointer", opacity: 0.5, }}>
    //                     {profile.avatar && <img src={getAvatarURL(profile)} alt={profile.username} width={40} />}
    //                     {!profile.avatar && <img src='https://cdn.discordapp.com/embed/avatars/0.png' alt={profile.username} width={40}/>}
    //                 </a>
    //                 <div className="profile" style={{ marginRight: 20, }}/>
    //                 <div className="hamburger profile" onClick={toggleMenu} style={{ alignItems: "center", }}>
    //                     <FaBars color="white" size={20} />
    //                 </div>
    //             </div>
    //         </div>
    //         </div>
    //     </div>
    //     )
    // }

    return (
        <div style={{ position: "fixed", display: "flex", alignItems: "center", justifyContent: "space-between", background: "#171717e0", boxShadow: "0 2px 10px 0 rgba(23, 23, 23, 0.88)", top: 0, zIndex: 100, width: "100%", height: 60, maxHeight: "40%", }} >
            <div style={{ display: "flex", justifyContent: "space-between", marginLeft: 20, marginRight: 20, width: "100%", }} >
                <a href="/" style={{ color: "white", textDecoration: "none", width: 150, fontSize: 20, display: "flex", alignItems: "center", }}>
                    <img src="https://assets.camming.xyz/photos/logo/logo2.png" alt="logo" width={40} style={{ borderRadius: 5, marginRight: 15, }} />
                    <span>Camming</span>
                </a>
                <div className={`topBarShortCutLink ${menuActive ? 'mobile-active' : ''}`} style={{ width: "40%", justifyContent: "space-between", alignItems: "center", animation: menuActive ? 'mobile-active-show 0.5s ease-out;' : 'mobile-active-hide 0.5s ease-out;', }}>
                    <TopBarShortCutLink onClick={() => navigate('/')} isActive={window.location.pathname == '/'}>
                        <FaHome style={{ marginRight: 10, cursor: "pointer", }} />
                        <span>Home</span>
                    </TopBarShortCutLink>
                    <TopBarShortCutLink onClick={() => navigate('/dashboard')} isActive={window.location.pathname.startsWith('/dashboard')}>
                        <TbLayoutDashboardFilled style={{ marginRight: 10, }} />
                        <span>Dashboard</span>
                    </TopBarShortCutLink>
                    <TopBarShortCutLink isActive={window.location.pathname == '/commands'}>
                        <BsSlashSquareFill style={{ marginRight: 10, }} />
                        <span>Commands</span>
                    </TopBarShortCutLink>
                    <TopBarShortCutLink onClick={() => navigate('/contact')} isActive={window.location.pathname == '/contact'}>
                        <MdEmail style={{ marginRight: 10, }} />
                        <span>Contact</span>
                    </TopBarShortCutLink>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: 150, }}>
                    {profile ? (
                        <a style={{ display: "flex", alignItems: "center", }}>
                            {profile.avatar ? (
                            <img src={getAvatarURL(profile)} alt={profile.username} width={40} style={{ borderRadius: "50%", }} />
                            ) : (
                            <img src='https://cdn.discordapp.com/embed/avatars/0.png' alt={profile.username} width={40} style={{ borderRadius: "50%", }} />
                            )}
                        </a>
                    ) : (
                        <a onClick={() => {
                            if (window.location.hostname == 'camming.xyz') {window.location.href = 'https://camming.xyz/api/auth/login'}
                            else {window.location.href = 'http://210.246.215.139:3001/auth/v2/login'}
                        }} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", backgroundColor: "#5973d4", width: 100, height: 40, borderRadius: 5, }}>
                            Login
                        </a>
                    )}
                    <div className="profile" style={{ marginRight: 20, }}/>
                    <div className="hamburger profile" onClick={toggleMenu} style={{ alignItems: "center", }}>
                        <FaBars color="white" size={20} />
                    </div>
                </div>
            </div>
        </div>
    );
};