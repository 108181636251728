import { useEffect, useContext, useState } from "react";
import { GuildContext } from "../utils/contexts/GuildContext";
import { BackgroundFixed, Container, DashCategoryMenu, DashPageGeneralInfo, Title } from "../utils/styles";
import { IoSettingsOutline, IoNewspaperOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { getIconURL } from "../utils/getURL";
import { PartialGuild } from "../utils/types";
import { Guild } from "discord.js";

export const DashboardPage = () => {
    const { guild } = useContext(GuildContext);
    const navigate = useNavigate();
    const [menuActive, setMenuActive] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState<boolean[]>([true, true, true]);
    const location = useLocation();
  
    const toggleSideBar = (index: number) => {
        setCategoryMenu((prev) =>
            prev.map((item, i) => (i === index ? !item : item))
        );
    };
        
    const toggleMenu = () => { setMenuActive(!menuActive) };

    useEffect(() => {
        if(!guild){ setTimeout(() => { navigate('/dashboard'); }, 1000); };
        
        console.info(guild);

        if(window.location.pathname.endsWith('/')){ navigate(`${window.location.pathname.slice(0, -1)}`) };

        const subMenu = document.querySelector('#sub-menu');
        if(subMenu){
            const tags = subMenu.querySelectorAll('[data-tag]');
            tags.forEach((a) => {
                if(a instanceof HTMLElement){
                    const tag = a.getAttribute('data-tag');
                    const style = `font-size: 10px; margin-left: 5px; padding: 0px 8px; text-align: center; border-radius: 6px;`;
                    if(tag == 'new'){
                        a.textContent = 'NEW';
                        a.style.cssText = `background-color: #11700080; color: #26ff00; border: #26ff00 1px solid; ${style}`;
                    }else if(tag == 'update'){
                        a.textContent = 'UPDATE';
                        a.style.cssText = `background-color: #000f7080; color: #24b2ff; border: #24b2ff 1px solid; ${style}`;
                    }else if(tag == 'beta'){
                        a.textContent = 'BETA';
                        a.style.cssText = `background-color: #5c007080; color: #ec99ff; border: #ec99ff 1px solid; ${style}`;
                    }else if(tag == 'premium'){
                        a.textContent = 'PREMIUM';
                        a.style.cssText = `background-color: #67700080; color: #eeff38; border: #eeff38 1px solid; ${style}`;
                    };
                };
            });
        };
    }, []);

    useEffect(() => {
        const subMenu = document.querySelector('#sub-menu');
        if(subMenu){
            const items = subMenu.querySelectorAll('div[id]');
            items.forEach(e => {
                if(e instanceof HTMLElement && e.id == window.location.pathname.split('/')[2]){
                    e.style.backgroundColor = '#ff474780';
                }else if(e instanceof HTMLElement){
                    e.style.backgroundColor = '';
                };
            });
        };
    }, [location.pathname]);

    return guild && guild.database ? (
        <html>
        <head>
            <title>Dashboard - Camming</title>
        </head>
        <body>
            {/* Background Fixed */}
            <BackgroundFixed id="background"/>
            
            {/* Top */}
            <div style={{ marginTop: '60px', }} />
            
            {/* Menu */}
            <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <div style={{ backgroundColor: '#ff666633', height: '85vh', width: '95vw', borderRadius: '30px', display: 'flex', flexDirection: 'row', }}>
                    <div style={{ position: 'absolute', zIndex: 2, width: '95%', }}>
                        <div onClick={toggleMenu} className={`dashMenuArrow ${menuActive ? 'show' : 'hide'}`}><img src="https://assets.camming.xyz/photos/icons/arrow-left.png" alt="show/hide" height={40}/></div>
                    </div>
                    <div className={`dashMenuBackDrop ${menuActive ? 'show' : 'hide'}`} onClick={() => setMenuActive(false)}/>
                    
                    <div id="category" className={`dashMenu ${menuActive ? 'show' : 'hide'}`}>
                        <div style={{ padding: '20px', }}>
                            <div id="back" onClick={() => navigate(`/dashboard`)} style={{ position: 'fixed', cursor: 'pointer', backgroundColor: 'rgba(255, 138, 138, 0.5)', borderRadius: 5, color: '#fff', textDecoration: 'none', padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content', }}>
                                <img src="https://assets.camming.xyz/photos/icons/back-interface-ui.png" height={20} width={20}/>back
                            </div>
                            
                            <div id="guild" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                {guild.icon ? <img src={getIconURL(guild)} height={80} /> : <img src="https://assets.camming.xyz/photos/icons/no_image.png" style={{ padding: 10, height: 50, width: 50, borderRadius: '20px 0 0 20px', border: '1px #fff solid', }}/>}
                                <span style={{ fontSize: 25, fontWeight: 900, paddingTop: 10, alignContent: 'center', }}>{guild.name}</span>
                            </div>
    
                            <div id="line" style={{ borderBottom: '1px #fff solid', margin: 20, }}/>

                            <DashCategoryMenu id="general" isShow={categoryMenu[0]} pathname={window.location.pathname}>
                                <div id="sidebar" onClick={() => toggleSideBar(0)}><div id="arrow">{">"}</div><div id="name">General</div></div>
                                <div id="sub-menu">
                                    <div>
                                        <div id="main" onClick={() => {navigate('/dashboard/main'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/info-circle.png" height={20}/>
                                            Server info
                                            <div data-tag="beta"/>
                                        </div>
                                        <div id="settings" onClick={() => {navigate('/dashboard/settings'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/setting.png" height={20} />
                                            Server Settings
                                            <div data-tag="beta"/>
                                        </div>
                                        <div id="premium" onClick={() => {navigate('/dashboard/premium'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/premium.png" height={20} />
                                            Get Premium
                                            <div data-tag="beta"/>
                                            <div data-tag="premium"/>
                                        </div>
                                    </div>
                                </div>
                            </DashCategoryMenu>

                            <DashCategoryMenu id="Function settings" isShow={categoryMenu[1]} pathname={window.location.pathname}>
                                <div id="sidebar" onClick={() => toggleSideBar(1)}><div id="arrow">{">"}</div><div id="name">Function Settings</div></div>
                                <div id="sub-menu">
                                    <div>
                                        {/* <div id="none" onClick={() => {navigate('/dashboard/none'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/none.png" height={20} />
                                            None
                                            <div data-tag="none"/>
                                        </div>
                                        <div id="none" onClick={() => {navigate('/dashboard/none'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/none.png" height={20} />
                                            None
                                            <div data-tag="none"/>
                                        </div> */}
                                    </div>
                                </div>
                            </DashCategoryMenu>

                            <DashCategoryMenu id="Other" isShow={categoryMenu[2]} pathname={window.location.pathname}>
                                <div id="sidebar" onClick={() => toggleSideBar(2)}><div id="arrow">{">"}</div><div id="name">Other</div></div>
                                <div id="sub-menu">
                                    <div>
                                        {/* <div id="none" onClick={() => {navigate('/dashboard/none'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/none.png" height={20} />
                                            None
                                            <div data-tag="none"/>
                                        </div>
                                        <div id="none" onClick={() => {navigate('/dashboard/none'); setMenuActive(false);}}>
                                            <img src="https://assets.camming.xyz/photos/icons/none.png" height={20} />
                                            None
                                            <div data-tag="none"/>
                                        </div> */}
                                    </div>
                                </div>
                            </DashCategoryMenu>
                        </div>
                    </div>

                    <div id="panel" style={{ width: '100%', overflow: 'auto', }}><div style={{ padding: '20px', }}>
                        {
                            // general
                            window.location.pathname.startsWith('/dashboard/main') ?
                            <div>
                                <div style={{ fontSize: '40px', fontWeight: 900, }}>Server Info</div>

                                <div id="line" style={{ borderBottom: '1px #fff solid', margin: '20px 50px', }}/>

                                <DashPageGeneralInfo>
                                    <div id="name">Name : {guild.name}</div>
                                    <div id="icon">Icon : <a href={getIconURL(guild)} target="_blank" style={{ color: '#8abbff', }}>{guild.icon}</a></div>
                                    <div id="id">Id : {guild.id}</div>
                                    <div id="ownerId">OwnerId : {guild.data.owner_id}</div>
                                    <div id="databaseId">DatabaseId : {guild.database.id || 'undefined'}</div>
                                    <div id="prefix">Prefix : {guild.database.prefix} <img src="https://assets.camming.xyz/photos/icons/pencil.png" alt="Edit" height={15} onClick={() => navigate('/dashboard/settings/#prefix')} style={{ cursor: 'pointer', marginLeft: 5, }} /></div>
                                </DashPageGeneralInfo>
                            </div> : window.location.pathname.startsWith('/dashboard/settings') ?
                            <div>
                                Settings Page
                            </div> : window.location.pathname.startsWith('/dashboard/premium') ?
                            <div>
                                Premium Page
                            </div>
                            
                            
                            : window.location.pathname = '/dashboard'
                        }
                    </div></div>
                </div>
            </div>
        </body>
        </html>
        // <div style={{ padding: '40px 0'}}>
        //     <Container>
        //         <div style={{ background: 'rgb(100 100 100)', padding: '20px', border: '5px solid rgb(116 116 116)', borderRadius: '10px', }}>
        //             <div style={{ display: 'flex', alignItems: 'center', height: '40px', }}>
        //                 <IoSettingsOutline size={30} style={{ paddingRight: '15px', }} />
        //                 <Title>Basic Configurations</Title>
        //             </div>
        //             <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', fontSize: '20px', }}>
        //                 <div style={{ padding: '12px', }}>
        //                     <div className='pointer' onClick={() => navigate(`/dashboard/prefix`)} style={{ padding: '10px', background: '#373737', width: '190px', borderRadius: '5px', }}>
        //                         Command Prefix
        //                     </div>
        //                 </div>
        //                 <div style={{ padding: '12px', }}>
        //                     <div className='pointer' onClick={() => navigate(`/dashboard/welcome`)} style={{ padding: '10px', background: '#373737', width: '210px', borderRadius: '5px', }}>
        //                         Welcome new member
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div style={{ padding: '10px', }}/><div style={{ border: '1px solid #8b8b8b', }}/><div style={{ padding: '10px', }}/>
        //         <div style={{ background: 'rgb(100 100 100)', padding: '20px', border: '5px solid rgb(116 116 116)', borderRadius: '10px', }}>
        //             <div style={{ display: 'flex', alignItems: 'center', height: '40px', }}>
        //                 <IoNewspaperOutline size={30} style={{ paddingRight: '15px', }} />
        //                 <Title>Channel Logs</Title>
        //             </div>
        //             <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', fontSize: '20px', }}>
        //                 <div style={{ padding: '12px', }}>
        //                     <div className='no-drop' style={{ padding: '10px', background: '#373737', width: '210px', borderRadius: '5px', }}>
        //                         Bot Update
        //                     </div>
        //                 </div>
        //                 <div style={{ padding: '12px', }}>
        //                     <div className='no-drop' style={{ padding: '10px', background: '#373737', width: '200px', borderRadius: '5px', }}>
        //                         Audit logs
        //                     </div>
        //                 </div>
        //                 <div style={{ padding: '12px', }}>
        //                     <div className='no-drop' style={{ padding: '10px', background: '#373737', width: '190px', borderRadius: '5px', }}>
        //                         Moderation Logs
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </Container>
        // </div>
    ) : (
        <div><BackgroundFixed id="background"/><div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "x-large", }}>You haven't chosen a guild yet!</div></div>
    );
};