import { useContext } from "react";
import { BackgroundFixed, HomePage1Button1 } from "../utils/styles"
import { GuildContext } from "../utils/contexts/GuildContext";

export const InvitePage = () => {
    const { guild } = useContext(GuildContext);

    return (
        <html>
            <head>
                <title>Guilds - Camming</title>
            </head>
            <BackgroundFixed id="background"/>
            <div style={{ marginTop: '100px', }}>
                <a href={`${window.location.origin}/dashboard`} style={{ margin: 20, padding: 10, backgroundColor: 'rgba(255, 138, 138, 0.5)', borderRadius: 10, color: '#fff', textDecoration: 'none',width: 'min-content', }}>{"<"}</a>
            </div>
            {guild ? 
                <div style={{  display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', width: '100vw' }}>
                    <h1>This server has no Camming bots.</h1>
                    <a href={`https://discord.com/oauth2/authorize?client_id=1210178730836893719&scope=bot+applications.commands&guild_id=${guild.id}&response_type=code&redirect_uri=${window.location.origin}/dashboard`} target="_top">
                        <HomePage1Button1 style={{ color: '#000' }}>Invite</HomePage1Button1>
                    </a>
                </div> : 
                <h1 style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>You haven't chosen a guild yet!</h1>
            }
        </html>
    );
}