import { useEffect, useState } from "react";
import { getMutualGuilds, getMutualGuildsLocalhost } from "../api";
import { PartialGuild } from "../types";

export function useFetchGuilds() {
    const [guilds, setGuilds] = useState<PartialGuild[]>();
    const [errorGuilds, setError] = useState('');
    const [loadingGuilds, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        // getMutualGuilds()
        // .then(({ data }) => {
        //     console.log(data);
        //     setGuilds(data);
        //     setLoading(false);
        // })
        // .catch((err) => {
        //     console.error(err);
        //     setError(err);
        // })
        // .finally(() => setTimeout(() => setLoading(false), 2000));

        if (window.location.hostname == 'camming.xyz') {
            getMutualGuilds()
            .then(({ data }) => {
                console.info('Guilds:', data);
                setGuilds(data);
                setLoading(false);
            })
            .catch(error => {
                setTimeout(() => {
                    getMutualGuilds()
                    .then(({ data }) => {
                        console.info('Guilds:', data);
                        setGuilds(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error(error);
                        setError(`${error}`);
                    })
                }, 5000);
            })
        } else if (window.location.hostname != 'camming.xyz')  {
            getMutualGuildsLocalhost()
            .then(({ data }) => {
                console.info('Guilds:', data);
                setGuilds(data);
                setLoading(false);
            })
            .catch(error => {
                setTimeout(() => {
                    getMutualGuildsLocalhost()
                    .then(({ data }) => {
                        console.info('Guilds:', data);
                        setGuilds(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error(error);
                        setError(`${error}`);
                    })
                }, 5000);
            })
        }
    }, []);

    return { guilds, errorGuilds, loadingGuilds };
}