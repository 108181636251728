import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { MenuPage } from './pages/MenuPage';
import { DashboardPage } from './pages/DashboardPage';
import { GuildPrefixPage } from './pages/GuildPrefixPage';
import { WelcomeMessagePage } from './pages/WelcomeMessagePage';
import { GuildContext } from './utils/contexts/GuildContext';
import { AppBar } from './components/AppBar';
import { Spinner } from './components/Spinner';
import { Loading, LoadingEnd } from './pages/LoadingPage';
import axios from 'axios';
import { HomePage } from './pages/HomePage';
import { wait } from '@testing-library/user-event/dist/utils';
import { PartialGuild } from './utils/types';
import { PageNotFound } from './pages/PageNotFound';
import { InvitePage } from './pages/InvitePage';
import { ContactPage } from './pages/ContactPage';

export const hideLoading = () => {
  setTimeout(() => {
    if (document.readyState === 'complete') {
      console.log('Loading: ' + document.readyState);
      const preLoaderBar = document.getElementById('PreLoaderBar');
      if (preLoaderBar) {
        preLoaderBar.style.display = 'none';
      }
    } else {
      console.log('Loading: ' + document.readyState);
      const preLoaderBar = document.getElementById('PreLoaderBar');
      if (preLoaderBar) {
        preLoaderBar.style.display = 'block';
      }
    }
  }, 2000);
};

function App() {
  const [guild, setGuild] = useState<PartialGuild>();
  // const { user, error, loading } = useFetchUser();

  // console.log('Checking server status...');
  // axios.get('https://camming.xyz/api/auth/status')
  //     .then(response => console.log(response.data))
  //     .catch(error => console.error('Error:', error));

  const updateGuild = (guild: PartialGuild) => setGuild(guild);

  // console.log('--------');
  // console.log(user);
  // console.log(error);

  // if (loading) return <Spinner children={<Loading />} />;

  // return <LoginPage />;

  if(document.location.pathname == '/discord'){
    document.location.href = 'https://discord.com/invite/brxPZB3Jzy';
  };if(document.location.pathname == '/invite'){
    document.location.href = 'https://discord.com/oauth2/authorize?client_id=1210178730836893719';
  };
  
  useEffect(() => {
    hideLoading();

    window.addEventListener('load', hideLoading);

    return () => {
      window.removeEventListener('load', hideLoading);
    };
  }, []);
  
  return (
    <GuildContext.Provider value={{ guild, updateGuild, }}>
      {/* <Routes>
        <Route path='*' element={<LoadingEnd />} />
      </Routes> */}
      {/* {user && !error ? (
        <> */}
        <Routes>
          <Route path='*' element={<div><AppBar /><div className="preLoaderBar backdrop-blur" id="PreLoaderBar"><div className="progress"><div className="indeterminate"></div></div></div></div>} />
        </Routes>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/homepagetest' element={<HomePage />} />
          {/* <Route path='/login' element={<Navigate to="/api/auth/login" />} /> */}
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/dashboard/invite' element={<InvitePage />} />
          <Route path='/dashboard' element={<MenuPage />} />
          <Route path='/dashboard/*' element={<DashboardPage />} />
          {/* <Route path='/dashboard/prefix' element={<GuildPrefixPage />} />
          <Route path='/dashboard/welcome' element={<WelcomeMessagePage />} /> */}
        {/* </Routes>
        </>
      ) : (
        <Routes> */}
          {/* <Route path='/' element={<LoginPage />} /> */}
          {/* <Route path='/login' element={<LoginPage />} /> */}
          {/* <Route path='/guilds' element={<Navigate to="/login" />} />
          <Route path='/dashboard/*' element={<Navigate to="/login" />} /> */}
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      {/* )} */}
    </GuildContext.Provider>
  );
}

export default App;
