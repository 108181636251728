import { getIconURL } from "../utils/getURL";
import { Container, GuildMenuItemStyle } from "../utils/styles"
import { PartialGuild } from "../utils/types";

type Props = {
    guild: PartialGuild;
}

export const GuildMenuItem = ({ guild }: Props) => (
    <GuildMenuItemStyle>
        {guild.icon && (
            <img
                src={getIconURL(guild)}
                alt={guild.name}
                width={40}
                height={40}
            />
        )}
        {!guild.icon && (
            <div style={{ width: '40px',  height: '40px', backgroundColor: 'rgba(255, 0, 0, 0.5)', alignContent: 'center', textAlign: 'center' }}>
                {guild.name.slice(0, 2)}
            </div>
        )}
        <p style={{ display: 'flex', justifyContent: 'right', height: '25px', width: '250px', marginTop: '16px', marginBottom: '16px' }}>{guild.name}</p>
    </GuildMenuItemStyle>
  );