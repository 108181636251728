import { BackgroundFixed, ContactPage1 } from "../utils/styles";

export const ContactPage = () => {
    return (
        <html>
        <head>
            <title>Contact - Camming</title>
        </head>
        <body>
            {/* Background */}
            <BackgroundFixed id="background"/>
            
            {/* Top */}
            <div style={{ marginTop: '70px', }} />
            
            {/* Part1 */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', }} >
                <ContactPage1>
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', }}>
                        <div>E-mail</div>
                        <div>Discord</div>
                    </div>
                    <div style={{ height: '100%', alignContent: 'center', }}><div style={{ borderRight: '1px #fff solid', height: '80%', }}/></div>
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', }}>
                        <div>cammingbot@gmail.com, thirphathrphlphun@gmail.com</div>
                        <a href="https://discord.com/invite/brxPZB3Jzy" style={{ color: '#fff', }}>https://discord.com/invite/brxPZB3Jzy</a>
                    </div>
                </ContactPage1>
            </div>
        </body>
        </html>
    )
};
